<template>
  <section>
    <b-sidebar title="Edit Milestone" v-model="showEditMilestone" right shadow>
      <edit-milestone :data="milestoneData" @saved="onChange" />
    </b-sidebar>

    <b-alert variant="secondary" show>
      <b-row cols="12" class="mb-1 alert-heading">
        <b-col class="d-flex align-items-center justify-content-between">
          <b-form-checkbox
            checked="true"
            hideShowFilter="true"
            @change="hideShowFilter"
            ><b>show filters</b></b-form-checkbox
          >

          <b-dropdown
            right
            size="sm"
            text="Actions"
            variant="gradient-secondary"
            id="dropdown-form"
            ref="dropdown"
          >
            <b-dropdown-form>
              <b-button
                block
                size="sm"
                class="w-100"
                variant="gradient-success"
                @click="downloadCSV"
              >
                <span class="text-nowrap"> Export Milestones </span>
              </b-button>
            </b-dropdown-form>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-alert>

    <b-row v-if="this.filtersHidden == false">
      <!-- <b-col sm="12" md="4" lg="4">
        <b-input-group class="input-group-merge mb-1">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="Search"
            v-model="filters.search"
            @input="onSearch"
          />
        </b-input-group>
      </b-col> -->
      <b-col sm="12" md="2" lg="2">
        <v-select
          class="mb-1"
          v-model="filters.milestone"
          label="lbl_name"
          :reduce="(lbl) => lbl.lbl_id"
          :options="$store.state.master.milestone"
          aria-placeholder="Milestone"
          placeholder="Milestone Name"
          @input="onFiltersChange"
        />
      </b-col>
      <b-col sm="12" md="2" lg="2">
        <v-select
          class="mb-1"
          v-model="filters.contract_nature"
          :options="contract_natures"
          aria-placeholder="Contract Nature"
          placeholder="Nature of Contract"
          @input="onFiltersChange"
        />
      </b-col>
      <b-col sm="12" md="4" lg="4">
        <v-select
          class="mb-1"
          v-model="filters.movie"
          :options="$store.state.master.movies"
          :filter-by="movieFilter"
          label="name"
          :reduce="(movie) => movie.id"
          placeholder="Movie"
          @input="onFiltersChange"
          @search="onSearchMovie"
        />
      </b-col>
      <b-col sm="12" md="4" lg="4">
        <v-select
          class="mb-1"
          v-model="filters.vendor"
          :options="$store.state.master.vendors"
          label="company"
          :reduce="(vendor) => vendor.vendor_id"
          placeholder="Customer"
          @input="onFiltersChange"
          @search="onSearchCustomer"
        />
      </b-col>
      <!-- <b-col sm="12" md="2" lg="2">
        <flat-pickr
          :config="{
            mode: 'range',
            ...$flatPickrConfig,
          }"
          placeholder="Planned Date"
          class="form-control mb-1"
          v-model="filters.duedate"
          @input="onFiltersChange"
        />
      </b-col> -->
      <b-col sm="12" md="4" lg="4" class="mt-">
        <div class="d-block text-left">
          <label>Planned date from</label>
          <flat-pickr
            v-model="filters.duedateFrom"
            :config="{
              ...$flatPickrConfig,
            }"
            placeholder="Date From"
            class="form-control mb-1"
            @input="onFiltersChange"
          />
        </div>
      </b-col>
      <b-col sm="12" md="4" lg="4" class="mt-">
        <div class="d-block text-left">
          <label>Planned date to</label>
          <flat-pickr
            v-model="filters.duedateTo"
            :config="{
              ...$flatPickrConfig,
            }"
            placeholder="Date To"
            class="form-control mb-1"
            @input="onFiltersChange"
          />
        </div>
      </b-col>
      <b-col sm="12" md="4" lg="4" class="mt-2">
        <b-form-checkbox
          v-model="filters.duedateEmpty"
          id="textareaduedatedate"
          @input="onFiltersChange"
          >Show Empty planned dates</b-form-checkbox
        >
      </b-col>

      <b-col sm="12" md="4" lg="4" class="mt-">
        <div class="d-block text-left">
          <label>Actual date from</label>
          <flat-pickr
            v-model="filters.actualdateFrom"
            :config="{
              ...$flatPickrConfig,
            }"
            placeholder="Date From"
            class="form-control mb-1"
            @input="onFiltersChange"
          />
        </div>
      </b-col>
      <b-col sm="12" md="4" lg="4" class="mt-">
        <div class="d-block text-left">
          <label>Actual date to</label>
          <flat-pickr
            v-model="filters.actualdateTo"
            :config="{
              ...$flatPickrConfig,
            }"
            placeholder="Date To"
            class="form-control mb-1"
            @input="onFiltersChange"
          />
        </div>
      </b-col>
      <b-col sm="12" md="4" lg="4" class="mt-2">
        <b-form-checkbox
          v-model="filters.actualdateEmpty"
          @input="onFiltersChange"
          id="textareaactualdate"
          >Show Empty actual dates</b-form-checkbox
        >
      </b-col>

      <b-col sm="12" md="4" lg="4" class="mt-">
        <div class="d-block text-left">
          <label>Payment date from</label>
          <flat-pickr
            v-model="filters.paymentdateFrom"
            :config="{
              ...$flatPickrConfig,
            }"
            placeholder="Date From"
            class="mb-1"
            @input="onFiltersChange"
          />
        </div>
      </b-col>
      <b-col sm="12" md="4" lg="4" class="mt-">
        <div class="d-block text-left">
          <label>Payment date from</label>
          <flat-pickr
            v-model="filters.paymentdateTo"
            :config="{
              ...$flatPickrConfig,
            }"
            placeholder="Date To"
            class="form-control mb-1"
            @input="onFiltersChange"
          />
        </div>
      </b-col>
      <b-col sm="12" md="4" lg="4" class="mt-2">
        <b-form-checkbox
          v-model="filters.paymentdateEmpty"
          id="textareapaymentdate"
          placeholder="Payment date filters will visible here"
          @input="onFiltersChange"
          >Show Empty payment dates</b-form-checkbox
        >
      </b-col>

      <!-- <b-col sm="12" md="2" lg="2">
        <flat-pickr
          :config="{
            mode: 'range',
            ...$flatPickrConfig,
          }"
          placeholder="Payment Date"
          class="form-control mb-1"
          v-model="filters.paymentdate"
          @input="onFiltersChange"
        />
      </b-col> -->

      <b-col sm="6" md="2" lg="2">
        <b-button
          size="sm"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="w-100 mt-3 mb-3"
          @click="clearFilters"
          >Reset</b-button
        >
      </b-col>
    </b-row>
    <b-card no-body v-if="$can('read', 'contract')">
      <b-table
        hover
        small
        bordered
        class="position-relative"
        primary-key="id"
        responsive
        :empty-text="tableEmptyText"
        show-empty
        :items="milestonesList"
        :fields="fields"
        @row-clicked="onRowClicked"
      >
        <!-- hover -->
        <template #head(sr)="data">
          <b-badge v-if="pagination.totalRows" variant="success">{{
            pagination.totalRows
          }}</b-badge>
        </template>
        <template #cell(sr)="data">
          <div style="width: max-content">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}

            <br />
            <div
              @click.stop="
                $router.push(`/contracts/form/${data.item.contract_id}`)
              "
            >
              <b-badge variant="light-primary"
                >{{
                  `#C${data.item.contract_nature[0]}${data.item.contract_id}`
                }}
              </b-badge>
              <br />
              <b-badge variant="light-primary"
                >{{ `${data.item.contract_nature}` }}
              </b-badge>
            </div>
          </div>
        </template>
        <template #cell(titles)="data">
          <div style="width: max-content" v-html="data.value"></div>
        </template>
        <template #cell(amountpaid)="data">
          {{ formatCurrency(data.value) }}
        </template>
        <template #cell(amountpayable)="data">
          {{ formatCurrency(data.value) }}
        </template>
        <template #cell(payable)="data">
          {{ formatCurrency(data.value) }}
        </template>
        <template #cell(actualdate)="data">
          <div style="width: max-content">
            {{ data.value ? moment(data.value).format("DD-MM-YYYY") : "" }}
          </div>
        </template>

        <template #cell(paymentdate)="data">
          <div style="width: max-content">
            {{ data.value ? moment(data.value).format("DD-MM-YYYY") : "" }}
          </div>
        </template>
        <template #cell(duedate)="data">
          <div style="width: max-content">
            {{ data.value ? moment(data.value).format("DD-MM-YYYY") : "" }}
          </div>
        </template>

        <template #cell(invoiceReceivedAmount)="data">
          <div class="text-nowrap">
            <b-form-input
              style="width: 150px"
              placeholder="Invoice No."
              v-model="data.item.invoice_unique_id"
            />

            <p>
              Total Amt:
              <b
                >{{ formatCurrency(data.item.invoice_total) }}
                {{ data.item.invoiceCurrency }}</b
              >
            </p>
            <p>
              Paid Amt:
              <b
                >{{ formatCurrency(data.item.invoiceReceivedAmount) }}
                {{ data.item.invoiceCurrency }}</b
              >
            </p>
            <p>Exh. Rt: {{ data.item.invoiceExRate }}</p>
            <b-button
              @click="
                () => {
                  showModal('Are you sure want to save?', 'save').then(
                    (value) => {
                      if (value) onSaveMilestoneInv(data.item);
                    }
                  );
                }
              "
              size="sm"
              variant="outline-success"
              >save</b-button
            >
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button-group size="sm" vertical>
              (M:{{ data.item.id }})
              <b-badge
                size="sm"
                @click="editMilestone(data.item)"
                v-if="$can('update', 'contract') && !data.item.deleted"
                variant="success"
                >Update Milestone</b-badge
              >

              <!-- <hr /> -->
              <!-- <b-badge
                variant="info"
                size="sm"
                @click.stop="
                  $router.push(`/contracts/form/${data.item.contract_id}`)
                "
                >View Contract</b-badge
              > -->
              <!-- <hr />
              <b-badge size="sm" variant="success"
                >Create Invoice - (M:{{ data.item.id }})</b-badge
              > -->
            </b-button-group>
          </div>
        </template>
        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>
      </b-table>

      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                :limit="15"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BBadge,
  BTable,
  BCol,
  BRow,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BPagination,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BModal,
  VBTooltip,
  VBToggle,
  BSidebar,
  BFormCheckbox,
  BAlert,
  BDropdownForm,
  BButtonGroup,
  BFormTextarea,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import * as ContractServices from "@/apiServices/ContractServices";
import * as RevenueServies from "@/apiServices/RevenueServies";
import { contract_natures, labels } from "@/utils/constants";
import { downloadFromURL, formatCurrency } from "@/utils/helpers";
import EditMilestone from "./EditMilestone.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { TokenService } from "@/apiServices/storageService";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import showModal from "../../../components/ModalConfirmation";

export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BBadge,
    BTable,
    BCol,
    BRow,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BPagination,
    FeatherIcon,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BModal,
    vSelect,
    flatPickr,
    EditMilestone,
    BSidebar,
    BFormCheckbox,
    BAlert,
    BDropdownForm,
    BButtonGroup,
    BFormTextarea,
  },
  data() {
    return {
      tableEmptyText: "LOADING...",

      contract_natures,
      filterModal: {
        dateFrom: "",
        dateTo: "",
        emptyDates: false,
      },

      filterName: "",
      // contract_statuses,
      filters: {
        search: "",
        milestone: "",
        contract_nature: "",
        vendor: "",
        movie: "",
        duedateFrom: "",
        duedateTo: "",
        actualdateFrom: "",
        actualdateTo: "",
        paymentdateFrom: "",
        paymentdateTo: "",
        duedateEmpty: false,
        paymentdateEmpty: false,
        actualdateEmpty: false,
      },

      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
        perPageOptions: [10, 25, 50, 100, "All"],
      },
      fields: [
        {
          key: "actions",
          label: "Actions",
          stickyColumn: true,
          variant: "primary",
        },
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "company",
          label: "Contact",
        },
        {
          key: "titles",
          label: "Movie",
        },
        {
          key: "milestone_name",
          label: "Name",
        },
        {
          key: "amountpaid",
          label: "Amount Paid",
        },
        {
          key: "amountpayable",
          label: "Amount Payable",
        },
        {
          key: "payable",
          label: "Payable %",
        },
        {
          key: "duedate",
          label: "Planned Date",
        },
        {
          key: "actualdate",
          label: "Actual Date",
        },

        // {
        //   key: "invoiceReceivedAmount",
        //   label: "Invoice Details",
        //   variant: "success",
        // },

        {
          key: "paymentdate",
          label: "Payment Date",
        },
      ],
      milestonesList: [],
      milestoneData: {},
      showEditMilestone: false,
      selectedContractId: null,
      filtersHidden: false,
      filterSelected: null,
      searchMovieTerm: "",
      searchCustomerTerm: "",
    };
  },
  methods: {
    showModal,

    moment,

    async onSaveMilestoneInv(data) {
      try {
        const response = await RevenueServies.UpdateInvoiceDataService({
          inv_number: data.invoice_unique_id,
          milestone_id: data.id,
        });
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.loadMilestonesList();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      } catch (error) {
        console.error("Error in onSaveMilestoneInv ", error);
      }
    },
    hideShowFilter() {
      this.filtersHidden = !this.filtersHidden;
    },
    formatCurrency,

    onRowClicked(item, index, event) {
      if (this.$can("update", "contract") && !item.deleted) {
        this.editMilestone(item);
      }
    },

    downloadCSV() {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }

        let urlStr = `${process.env.VUE_APP_BASEURL}/contract/sheet/milestone/download?authorization=${token}`;

        downloadFromURL(urlStr, "Milestone", "csv");
      } catch (error) {
        console.error(`Error in downloadCSV `, error);
      }
    },

    movieFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      // console.log("Filtering:", { option, search });
      return (
        option.name && option.name.toString().toLowerCase().indexOf(temp) > -1
      );
    },

    clearFilters() {
      this.filters = {
        search: "",
        status: "",
        contract_nature: "",
        vendor: "",
        movie: "",
        territories: [],
        rights: [],
        languages: [],
        from: "",
        to: "",
      };
      this.pagination = {
        ...this.pagination,
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      };
      this.onChange();
    },

    onFiltersChange() {
      this.pagination.currentPage = 1;
      this.onChange();
    },

    editMilestone(data) {
      this.showEditMilestone = true;
      this.milestoneData = data;
    },

    async getMilestones() {
      const loader = this.$loading.show();

      try {
        const response = await ContractServices.getAllMilestones({
          limit: this.pagination.limit,
          page: this.pagination.currentPage,
          ...this.filters,
        });
        if (!response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Failed",
              icon: "EditIcon",
              variant: "primary",
            },
          });
          return;
        }

        this.milestonesList = response.data.data.milestones;

        if (response.data.data.count) {
          this.pagination.totalRows = response.data.data.count;
        }
        if (!this.milestonesList.length)
          this.tableEmptyText = "NO RECORDS FOUND";
      } catch (error) {
        console.error("Error in getting milestones List ", error);
        this.tableEmptyText = "Error in getting milestones";
      } finally {
        loader.hide();
      }
    },

    onChange() {
      this.$nextTick(() => {
        this.getMilestones();
        localStorage.setItem(
          "filtersAndPaginationMilestones",
          JSON.stringify({
            filters: this.filters,
            pagination: this.pagination,
          })
        );
      });
    },

    onSearch() {
      if (this.debouce) clearTimeout(this.debouce);
      this.debouce = setTimeout(() => {
        this.onFiltersChange();
      }, 1000);
    },

    loadMilestonesList() {
      const filtersAndPaginationMilestonesStr = localStorage.getItem(
        "filtersAndPaginationMilestones"
      );
      if (filtersAndPaginationMilestonesStr) {
        const obj = JSON.parse(filtersAndPaginationMilestonesStr);
        this.pagination = obj.pagination;
        this.filters = obj.filters;
      }
      this.getMilestones();
    },
    onSearchMovie(searchMovieTerm) {
      this.searchMovieTerm = searchMovieTerm;
      this.$store.dispatch("master/setMoviesMaster", {
        search: searchMovieTerm,
      });
    },
    onSearchCustomer(searchCustomerTerm) {
      this.searchCustomerTerm = searchCustomerTerm;
      this.$store.dispatch("master/setVendorsMaster", {
        search: searchCustomerTerm,
      });
    },
  },

  mounted() {
    this.loadMilestonesList();

    this.$store.dispatch("master/setVendorsMaster");
    this.$store.dispatch("master/setMoviesMaster");

    this.$store.dispatch("master/setLabelMaster", { key: labels.milestone });
  },
};
</script>

<style></style>
